import React, { useState } from 'react';
import Breadcumb from '../../Layouts/Breadcumb';
import ReplyQuestion from './ReplyQuestion';

export default function Qadetails() {
    const [visibleReplyForms, setVisibleReplyForms] = useState([false, false, false]);

    // Function to toggle visibility of a specific reply form
    const toggleVisibility = (index) => {
        const newVisibleReplyForms = [...visibleReplyForms];
        newVisibleReplyForms[index] = !newVisibleReplyForms[index];
        setVisibleReplyForms(newVisibleReplyForms);
    };

    return (
        <>
            <Breadcumb pagetitle="Get the list of containers in pod" />
            <section className='py-5'>
                <div className='container'>
                    <div className='qalistbox mb-4 shadow-lg'>
                        <div className='d-flex'>
                            <div className='qauserimg'>
                                <a href='/qa-details'><img src='assets/images/author-11.jpg' alt='author' /></a>
                            </div>
                            <div className='qapostinfo'>
                                <h3 className='h6 mb-1'><a href='/qa-details' className='text-dark'>By: Arden Smith</a></h3>
                                <ul className='mb-3'>
                                    <li>Feb 25, 2024</li>
                                    <li>Programmer</li>
                                    <li><i className="fa-light fa-message-lines"></i> 3</li>
                                </ul>
                            </div>
                        </div>
                        <h3 className='qaposttitle mb-3'><a href='#' className='text-dark'>Get the list of containers in pod</a></h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>

                        <button type='button' onClick={() => toggleVisibility(0)} className='border-0 bg-transparent text-success fw-semibold'>
                            <i className="fa-light fa-reply"></i> Reply
                        </button>
                        {visibleReplyForms[0] && (
                            <div className='mt-2'>
                                <ReplyQuestion />
                            </div>
                        )}

                        <div className='replyPost mt-4 ps-3'>
                            <div className='bg-light rounded p-3 mb-2'>
                                <div className='d-flex'>
                                    <div className='qauserimg'>
                                        <a href='/qa-details'><img src='assets/images/author-11.jpg' alt='author' /></a>
                                    </div>
                                    <div className='qapostinfo'>
                                        <h3 className='h6 mb-1'><a href='/qa-details' className='text-dark'>By: Arden Smith</a></h3>
                                        <ul className='mb-3'>
                                            <li>Feb 25, 2024</li>
                                            <li>Programmer</li>
                                            <li><i className="fa-light fa-message-lines"></i> 3</li>
                                        </ul>
                                    </div>
                                </div>
                                <h3 className='qaposttitle mb-3 h5'><a href='#' className='text-dark'>Get the list of containers in pod</a></h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
                                <button type='button' onClick={() => toggleVisibility(1)} className='border-0 bg-transparent text-success fw-semibold'>
                                    <i className="fa-light fa-reply"></i> Reply
                                </button>
                                {visibleReplyForms[1] && (
                                    <div className='mt-2'>
                                        <ReplyQuestion />
                                    </div>
                                )}
                            </div>

                            <div className='bg-light rounded p-3 mb-2'>
                                <div className='d-flex'>
                                    <div className='qauserimg'>
                                        <a href='/qa-details'><img src='assets/images/author-11.jpg' alt='author' /></a>
                                    </div>
                                    <div className='qapostinfo'>
                                        <h3 className='h6 mb-1'><a href='/qa-details' className='text-dark'>By: Arden Smith</a></h3>
                                        <ul className='mb-3'>
                                            <li>Feb 25, 2024</li>
                                            <li>Programmer</li>
                                            <li><i className="fa-light fa-message-lines"></i> 3</li>
                                        </ul>
                                    </div>
                                </div>
                                <h3 className='qaposttitle mb-3 h5'><a href='#' className='text-dark'>Get the list of containers in pod</a></h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry...</p>
                                <button type='button' onClick={() => toggleVisibility(2)} className='border-0 bg-transparent text-success fw-semibold'>
                                    <i className="fa-light fa-reply"></i> Reply
                                </button>
                                {visibleReplyForms[2] && (
                                    <div className='mt-2'>
                                        <ReplyQuestion />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
