import React from 'react'
import Homebanner from './Homebanner'
import Blockone from './Blockone'
import Blocktwo from './Blocktwo'
import Qablock from './Qablock'

export default function () {
  return (
    <>
        <Homebanner/>
        <Blockone/>
        <Blocktwo/>
        {/* <Qablock/> */}
    </>
  )
}
