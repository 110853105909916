import React from 'react'
import {
    BrowserRouter as Router,
    Link
  } from "react-router-dom";
export default function Qalist() {
  return (
    <>
        <div className='qalistbox mb-4 shadow-lg'>
            <div className='d-flex'>
                <div className='qauserimg'>
                    <Link to='/qa-details'><img src='assets/images/author-11.jpg'/></Link>
                </div>
                <div className='qapostinfo'>
                    <h3 className='h6 mb-1'><Link to='/qa-details' className='text-dark'>By: Arden Smith</Link></h3>
                    <ul className='mb-3'>
                        <li>Feb 25, 2024</li>
                        <li>Programmer</li>
                        <li><i class="fa-light fa-message-lines"></i> 3</li>
                    </ul>
                </div>
            </div>
            <h3 className='qaposttitle mb-3'><a href='javascript:void(0)' className='text-dark'>Get the list of containers in pod</a></h3>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially </p>
            <Link to='/qa-details' className='text-success fw-semibold'>View Details</Link>
        </div>
        <div className='qalistbox mb-4 shadow-lg'>
            <div className='d-flex'>
                <div className='qauserimg'>
                    <Link to='/qa-details'><img src='assets/images/author-10.jpg'/></Link>
                </div>
                <div className='qapostinfo'>
                    <h3 className='h6 mb-1'><Link to='/qa-details' className='text-dark'>By: Arden Smith</Link></h3>
                    <ul className='mb-3'>
                        <li>Feb 25, 2024</li>
                        <li>Programmer</li>
                        <li><i class="fa-light fa-message-lines"></i> 3</li>
                    </ul>
                </div>
            </div>
            <h3 className='qaposttitle mb-3'><Link to='/qa-details' className='text-dark'>Get the list of containers in pod</Link></h3>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially </p>
            <Link to='/qa-details' className='text-success fw-semibold'>View Details</Link>
        </div>
        <div className='qalistbox mb-4 shadow-lg'>
            <div className='d-flex'>
                <div className='qauserimg'>
                    <Link to='/qa-details'><img src='assets/images/author-11.jpg'/></Link>
                </div>
                <div className='qapostinfo'>
                    <h3 className='h6 mb-1'><a href='/qa-details' className='text-dark'>By: Arden Smith</a></h3>
                    <ul className='mb-3'>
                        <li>Feb 25, 2024</li>
                        <li>Programmer</li>
                        <li><i class="fa-light fa-message-lines"></i> 3</li>
                    </ul>
                </div>
            </div>
            <h3 className='qaposttitle mb-3'><Link to='/qa-details' className='text-dark'>Get the list of containers in pod</Link></h3>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially </p>
            <Link to='/qa-details' className='text-success fw-semibold'>View Details</Link>
        </div>
    </>
  )
}
