import React, {useState} from 'react';
import {
    BrowserRouter as Router,
    Link
  } from "react-router-dom";

export default function Login() {
   
  return (
    <>
    <section className='logscreen'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-6'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-9 col-md-6'>
                            <form className="p-4" action='/dashboard'>
                                <h3 className="mb-3 fw-bold text-center">SIGN IN</h3>
                                <p className='text-center mb-4'>Welcome back! Login to access the The Cognize</p>
                                <div className="mb-3">
                                    <input type="email" className="form-control" id="exampleInputEmail" name='email' required placeholder='User Name'/>
                                </div>
                                <div className="mb-3">
                                    <input type="password" className="form-control" id="exampleInputPassword" name='password'
                                    required placeholder='Password'/>
                                </div>
                                
                                <button type="submit" className="btn btn-warning w-100 fw-semibold text-uppercase">Continue</button>
                                <p className='mt-3 mb-2 text-center'><a href='javascript:void(0)' className='text-dark'> Forgot your password?</a></p>
                                <p className='mt-2 text-center'>Don't have account? <Link to='/register' className='text-success'>Create an account</Link></p>
                            </form>
                        </div>
                    </div>
                    



                    
                </div> 
            </div>
        </div>
    </section>    
    </>
  )
}
