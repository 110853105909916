import React, { useEffect, useState } from 'react';
import Breadcumb from '../../Layouts/Breadcumb';
import {BrowserRouter as Router,  Link } from "react-router-dom";
import axios from 'axios';
export default function Blog() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get('https://thecognize.com/admin/api/blogList')
            .then((response) => {
                if (response.data.success) {
                    setData(response.data.data);
                } else {
                    setError(response.data.message);
                }
                setLoading(false);
            })
            .catch((error) => {
              
                console.error('Error fetching data:', error.response ? error.response.data : error.message);
                setError('Failed to fetch data');
                setLoading(false);
            });
    }, []);
   
    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p className="text-center">{error}</p>;
    }
 
    return (
        <>
            <Breadcumb pagetitle='Blog' />
            <section className='py-5'>
                <div className='container'>
                    <div className='row'>
                        {data.length > 0 ? (
                            data.map((item, index) => (
                                <div className='col-lg-4 col-md-6 mb-4' key={index}>
                                    <div className='blog-box p-3 border rounded-4 shadow'>
                                        <div className='blocimg'>
                                            <Link to='/blog-details/'>

                                                <img src={item.image} alt={item.title}  onError={(e) => e.currentTarget.src = '/assets/images/blog1.jpg'}/>
                                            </Link>
                                        </div>
                                        <div className='blogcontent'>
                                            <h2 className='h4 my-2'>
                                                <Link to='/blog-details/' className='text-dark'>{item.title}</Link>
                                            </h2>
                                            <div className='d-flex gap-3 dashoption mb-3'>
                                                <div>
                                                    <Link to='/blog-details/' className='text-muted'>
                                                        <i className="far fa-user"></i> Admin
                                                    </Link>
                                                </div>
                                                <div>
                                                    <Link to='/blog-details/' className='text-muted'>
                                                        <i className="far fa-calendar-alt"></i> {new Date(item.created).toLocaleDateString()}
                                                    </Link>
                                                </div>
                                            </div>
                                            <p>{item.description}</p>
                                            <Link to='/blog-details/' className='btn btn-success px-4'>Continue</Link>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No blog posts available.</p>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}
