import React from 'react'

export default function Blocktwobox() {
  return (
    <>
    <div className='row g-4 boxpnl'>
        <div className='col-lg-3 col-md-6'>
            <div className='boxgrow'>
                <div className='iconLearn'>
                    <i class="fa-sharp fa-light fa-laptop-code"></i>
                </div>
                <h5>Write the script of the future</h5>
                <p>Thecognize is an open community designed for anyone who codes, where you can get answers to your toughest coding questions and share knowledge with your colleagues.</p>
            </div>
        </div>
        <div className='col-lg-3 col-md-6'>
            <div className='boxgrow'>
                <div className='iconLearn'>
                    <i class="fa-light fa-screwdriver-wrench"></i>
                </div>
                <h5>Support Open Source</h5>
                <p>Thecognize supports open source by encouraging collaboration and knowledge sharing. Our platform helps you connect with others to contribute to and learn from open source projects.</p>
            </div>
        </div>
        <div className='col-lg-3 col-md-6'>
            <div className='boxgrow'>
                <div className='iconLearn'>
                    <i class="fa-light fa-messages-question"></i>
                </div>
                <h5>Acquire and share knowledge</h5>
                <p>Acquire and share knowledge: you can acquire and share knowledge effortlessly. Our platform facilitates learning and collaboration within the coding community.</p>
            </div>
        </div>
        <div className='col-lg-3 col-md-6'>
            <div className='boxgrow'>
                <div className='iconLearn'>
                    <i class="fa-light fa-golf-flag-hole"></i>
                </div>
                <h5>Find career opportunities</h5>
                <p>Thecognize helps you find career opportunities by connecting you with industry professionals and showcasing job openings. Explore new paths and advance your career within our vibrant community.</p>
            </div>
        </div>
    </div>
    </>
  )
}
