import React from 'react'
import PropTypes from 'prop-types'

import {
    BrowserRouter as Router,
    Link
  } from "react-router-dom";


export default function Header(props) {
  return (
    <> 
    <div className="page-loader">
        <div className="spinner"></div>
    </div>

    <header className="mainhead">
        <div className='tophead'>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-lg-6 col-md-8'>
                        <div className='d-flex justify-content-md-start justify-content-center'>
                            <p className='mb-0 me-lg-5 me-3'><Link href='javascript:void(0);' className=' text-white'><i className="fas fa-envelope text-warning"></i> info@thecognize.com</Link></p>
                            <ul className='topSocial'>
                                <li><a href="https://m.facebook.com/TheCognize1" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                <li><a href="https://youtube.com/channel/UCgOumYQAdrS1KOYv6eLOWqQ" target="_blank"><i className="fab fa-youtube"></i></a></li>
                                <li><a href="https://www.linkedin.com/company/thecognize" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                                <li><a href="https://t.me/joinchat/K7HH4Bu9O2x6wQ5oEbyiHg" target="_blank"><i className="fab fa-telegram-plane"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-4 text-center text-lg-end text-md-end'>
                        {/* <Link className="me-4 text-white position-relative mt-1 d-inline-block" to="/">
                            <i class="fa-regular fa-bell"></i>
                            <span className='roundednotofication'>1</span>
                        </Link> */}
                        <Link className="btn btn-warning py-1 px-4 text-white rounded" to="/login">{props.loginText}</Link>
                    </div>
                </div>
            </div>
        </div>
        <nav className="navbar navbar-expand-lg">
            <div className="container">
                <Link className="navbar-brand" to="/"><img src="assets/images/logo2.png" alt="Logo"/><img src="assets/images/logo.png" alt="Logo" style={{height: "43px"}}/></Link>
                
                <div className='d-flex align-items-center'>
                    <div className='topsearch me-md-4'>
                        <form>
                            <input type='text' placeholder='Search here...' className='topsrchinput'/>
                            <button className='searchbtn'><i className="fa-light fa-magnifying-glass"></i></button>
                        </form>
                    </div>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to="/">{props.home}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/aboutus">{props.aboutText}</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link " to="/ask-question">Ask Question</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link " to="/mcq">MCQ</Link>
                            </li>
                         <li className="nav-item">
                                <Link className="nav-link " to="/blog">Blog</Link>
                            </li> 
                            <li className="nav-item">
                                <Link className="nav-link " to="/contact">{props.contactText}</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>    
            </div>
        </nav>
    </header>
      
    </>
  )
}

Header.propType = { home: PropTypes.string,
  aboutText: PropTypes.number
}
