import React from 'react'
import Breadcumb from '../../Layouts/Breadcumb'

export default function BlogDetails() {
  return (
    <>
    <Breadcumb pagetitle="Blog Details"/>
    <section className='py-5'>
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-lg-10'>
                    <div className='blog-box p-3 border rounded-4'>
                        <div className='blocimg'>
                            <a href='#'><img src='/assets/images/blog1.jpg' alt="blog1"/></a>
                        </div>
                        <div className='blogcontent'>
                            <h2 className='h4 mt-3 mb-2'><a href='#' className='text-dark'>Top best hacking movie to watch</a></h2>
                            <div className='d-flex gap-3 dashoption mb-3'>
                                <div><a href='#' className='text-muted'><i class="far fa-user"></i> Admin</a></div>
                                <div><a href='#' className='text-muted'><i class="far fa-calendar-alt"></i> Oct 22,2023</a></div>
                            </div>
                            <p>There have been many hacking-themed movies over the years, and opinions on the best ones can vary. Here are some of the top hacking movies that have been popular with audiences and critics</p>
                            <p>There have been many hacking-themed movies over the years, and opinions on the best ones can vary. Here are some of the top hacking movies that have been popular with audiences and critics</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
