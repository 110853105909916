import React from 'react'
import Blockonebox from './Blockonebox'

export default function Blockone() {
  return (
    <>
        <section className='py-5'>
            <div className='container'>
                <div className='row justify-content-center mb-4'>
                    <div className='col-lg-8 text-center'>
                        <h2 className='text-center h1 fw-bold mb-4'>For Competitive exam and job interview preparation Community</h2>
                        <p className='text-muted'> Join our community dedicated to competitive exam and job interview preparation. Exchange tips, practice questions, and strategies to ace your next challenges.</p>
                    </div>
                </div>
                <Blockonebox/>
            </div>
        </section>
    </>
  )
}
