import React from 'react'
import {BrowserRouter as Router,
    Link } from "react-router-dom";


export default function Footer(props) {
  return (
    <>
    {/* <Router> */}
    <footer>
        
        <div className="footerbottom text-center">
            <div className="container">
                <div className='footerlogo'>
                    <Link to='/'><img src="assets/images/logo.png" alt="Logo" /></Link>
                </div>
                <ul className="soacialnav my-3">
                    <li><a href="https://m.facebook.com/TheCognize1" target="_blank"><i className="fa-brands fa-facebook"></i></a></li>
                    <li><a href="https://youtube.com/channel/UCgOumYQAdrS1KOYv6eLOWqQ" target="_blank"><i className="fa-brands fa-youtube"></i></a></li>
                    <li><a href="https://www.linkedin.com/company/thecognize" target="_blank"><i className="fa-brands fa-linkedin"></i></a></li>
                    <li><a href="https://t.me/joinchat/K7HH4Bu9O2x6wQ5oEbyiHg" target="_blank"><i className="fa-brands fa-telegram"></i></a></li>
                </ul>
                <ul className="footernav">
                    <li><Link to="/aboutus">About Us</Link></li>
                    <li><Link to="/ask-question">Ask Question</Link></li>
                    <li><Link to="/mcq">MCQ</Link></li>
                    {/* <li><Link to="/blog">Blog</Link></li> */}
                    <li><Link to="/contact">Contact Us</Link></li>
                </ul>
                
            </div>
        </div>
        <div className="pt-3 pb-2 copyrightblock">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 order-lg-2 text-center text-lg-end">
                        <ul className="footernav">
                        <li><Link to="/privacypolicy">Privacy Policy</Link></li>
                        <li><Link to="/termandconditions">Terms & Conditions</Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-6 order-lg-1 text-center text-lg-start">
                        <p className="mb-2 text-white">2024 © The Cognize. All rights reserved.</p>
                    </div>
                
                </div>
            </div>
        </div>
    </footer>
    
        {/* <Link to="/">Home</Link>
        <Link to="/aboutus">About Us</Link>
        <Switch>
            <Route exact path="/"><Home/></Route>
            <Route exact path="/aboutus"><Aboutus/></Route>
        </Switch>
    </Router> */}
    
    </>
  )
}
