import React from 'react';
import {
    BrowserRouter as Router,
    Link
  } from "react-router-dom";

export default function Questioset() {
  return (
    <>
        <Link to='/mcq-list' className='questioset mb-2'>
            <div className='d-flex justify-content-between'>
                <div>
                    Certified Ethical Hacker exam questions set -1
                </div>
                <div>
                    <span className='quMore'><i class="fa-sharp fa-regular fa-arrow-right"></i></span>
                </div>
            </div>
        </Link>
        <Link to='/mcq-list' className='questioset mb-2'>
            <div className='d-flex justify-content-between'>
                <div>
                    UPSC MCQs
                </div>
                <div>
                    <span className='quMore'><i class="fa-sharp fa-regular fa-arrow-right"></i></span>
                </div>
            </div>
        </Link>
        <Link to='/mcq-list' className='questioset mb-2'>
            <div className='d-flex justify-content-between'>
                <div>
                    Current affairs 5 july 2022
                </div>
                <div>
                    <span className='quMore'><i class="fa-sharp fa-regular fa-arrow-right"></i></span>
                </div>
            </div>
        </Link>
        <Link to='/mcq-list' className='questioset mb-2'>
            <div className='d-flex justify-content-between'>
                <div>
                Certified Ethical Hacking(CEH) Exam Set -2
                </div>
                <div>
                    <span className='quMore'><i class="fa-sharp fa-regular fa-arrow-right"></i></span>
                </div>
            </div>
        </Link>
        <Link to='/mcq-list' className='questioset mb-2'>
            <div className='d-flex justify-content-between'>
                <div>
                    Certified Ethical Hacker exam questions set -1
                </div>
                <div>
                    <span className='quMore'><i class="fa-sharp fa-regular fa-arrow-right"></i></span>
                </div>
            </div>
        </Link>
        <Link to='/mcq-list' className='questioset mb-2'>
            <div className='d-flex justify-content-between'>
                <div>
                    UPSC MCQs
                </div>
                <div>
                    <span className='quMore'><i class="fa-sharp fa-regular fa-arrow-right"></i></span>
                </div>
            </div>
        </Link>
        <Link to='/mcq-list' className='questioset mb-2'>
            <div className='d-flex justify-content-between'>
                <div>
                    Current affairs 5 july 2022
                </div>
                <div>
                    <span className='quMore'><i class="fa-sharp fa-regular fa-arrow-right"></i></span>
                </div>
            </div>
        </Link>
        <Link to='/mcq-list' className='questioset mb-2'>
            <div className='d-flex justify-content-between'>
                <div>
                Certified Ethical Hacking(CEH) Exam Set -2
                </div>
                <div>
                    <span className='quMore'><i class="fa-sharp fa-regular fa-arrow-right"></i></span>
                </div>
            </div>
        </Link>
    </>
  )
}
