import React from 'react'
import Breadcumb from '../../Layouts/Breadcumb'
import Mcqsearch from './Mcqsearch'
import Questioset from './Questioset'
import Pagination from '../Pagination'

export default function Mcq() {
  return (
    <>
    <Breadcumb pagetitle="MCQ" />
    <section className='py-3 border-bottom'>
        <div className='container'>
            <div className='row justify-content-between align-items-center'>
                <div className='col-lg-3 d-flex gap-2 align-items-center mb-2 mb-lg-0'>
                    <label className='flex-fill'>Filter:</label>
                    <select className='form-control form-select rounded-0'>
                        <option>Most Popular</option>
                        <option> Current Affair</option>
                        <option> GK</option>
                    </select>
                </div>
                <div className='col-lg-5'>
                    <Mcqsearch/>
                </div>
            </div>
        </div>
    </section>
    <section className='py-5'> 
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-lg-10'>
                    <Questioset/>
                </div>    
            </div>
        </div>
    </section>
    <section className='p2-4 pb-4'>
        <div className='container'>
            <Pagination/>
        </div>
    </section>
    </>
  )
}
