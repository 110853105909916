import { Breadcrumbs } from '@mui/material'
import React from 'react'
import Qalist from '../Home/Qalist'
import Breadcumb from '../../Layouts/Breadcumb'

export default function RecentQA() {
  return (
    <>
        <Breadcumb pagetitle="Recent Question Answer"/>
        <section className='py-5'>
            <div className='container'>
                <Qalist/>
            </div>
        </section>
    </>
  )
}
