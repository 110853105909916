import { Breadcrumbs } from '@mui/material'
import React from 'react'
import DashboardNav from './DashboardNav'
import Questioset from '../Mcq/Questioset'

export default function UserQuestion() {
    return (
        <>
            <Breadcrumbs pagetitle="User Question" />
            <section className='py-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-3'>
                            <DashboardNav />
                        </div>
                        <div className='col-lg-9'>
                            <div className='border rounded overflow-hidden'>
                                <h3 className='h5 px-4 py-3  border-bottom bg-success text-white'>User Question</h3>
                                <div className='px-4 py-3'>
                                    <a href='/mcq-list' className='questioset mb-2'>
                                        <div className='d-flex justify-content-between'>
                                            <div>
                                                Certified Ethical Hacker exam questions set -1
                                            </div>
                                            <div>
                                                <span className='quMore'><i class="fa-sharp fa-regular fa-arrow-right"></i></span>
                                            </div>
                                        </div>
                                    </a>
                                    <a href='/mcq-list' className='questioset mb-2'>
                                        <div className='d-flex justify-content-between'>
                                            <div>
                                                UPSC MCQs
                                            </div>
                                            <div>
                                                <span className='quMore'><i class="fa-sharp fa-regular fa-arrow-right"></i></span>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
