import React from 'react'

export default function ReplyQuestion() {
  return (
    <>
    <div>
        <form>
            <div className='d-flex w-100 p-3 border bg-white rounded gap-3'>
                <div className='flex-fill'>
                    <input type='text' className='form-control' placeholder='Post your comment..'/>
                </div>
                <div>
                    <button className='btn btn-success'>Submit</button>
                </div>
            </div>
            
        </form>
    </div>
    </>
  )
}
