import React from 'react'
import Breadcumb from '../../Layouts/Breadcumb'

export default function Contact() {
  return (
    <>
        <Breadcumb pagetitle="Contact Us"/>
        <section className='py-5'>
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-lg-6'>
                <form className="p-0 contactForm">
                  <h3 className="mb-3 fw-bold">Send Us A Message</h3>
                  <div className='row'>
                    <div className="mb-3 col-lg-6">
                        <label>Name</label>
                        <input type="text" className="form-control" id="exampleInputName" name='name' required placeholder='User Name'/>
                    </div>
                    <div className="mb-3 col-lg-6">
                        <label>Email</label>
                        <input type="email" className="form-control" id="exampleInputEmail" name='email' required placeholder='Email Address'/>
                    </div>
                    <div className="mb-3 col-lg-6">
                        <label>Contact Number</label>
                        <input type='tel' className='form-control' placeholder='Contact Number'/>
                    </div>
                    <div className="mb-3 col-lg-6">
                        <label>Subject</label>
                        <input type='text' className='form-control' placeholder='Subject'/>
                    </div>
                    <div className="mb-3 col-lg-12">
                        <label>Message</label>
                        <textarea className='form-control' placeholder='Message' rows="7"></textarea>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-warning w-100 fw-semibold text-uppercase">Submit</button>
                </form>
              </div>
              <div className='col-lg-4 mt-5'>
                <div className='mb-4'>
                  <h4 className='h5 text-success'><i class="fa-regular fa-location-dot"></i>  Address</h4>
                  <p>New Delhi</p>
                </div>
                <div className='mb-4'>
                  <h4 className='h5 text-success'><i class="fa-regular fa-envelopes"></i>  Email Address</h4>
                  <p> info@thecognize.com</p>
                </div>
               
              </div>
            </div>
          </div>
        </section>
    </>
  )
}
