import React from 'react'
import {
    BrowserRouter as Router,
    Link
  } from "react-router-dom";
export default function Register() {
  return (
    <>
    <section className='logscreen'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-6'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-9 col-md-6'>
                            <form className="p-4">
                                <h3 className="mb-3 fw-bold text-center text-uppercase">Create an Account</h3>
                                <p className='text-center mb-4'>Welcome! Register to access the The Cognize</p>
                                <div className="mb-3">
                                    <input type="text" className="form-control" name='name' required placeholder='Name'/>
                                </div>
                                <div className="mb-3">
                                    <input type="email" className="form-control" name='email' required placeholder='Email Id'/>
                                </div>
                                <div className="mb-3">
                                    <input type="password" className="form-control" name='password'
                                    required placeholder='Password'/>
                                </div>
                                <div className="mb-3">
                                    <input type="password" className="form-control" name='confirm-password'
                                    required placeholder='Confirm Password'/>
                                </div>
                                
                                <button type="submit" className="btn btn-warning w-100 fw-semibold text-uppercase">Continue</button>
                                <p className='mt-3 text-center'>Existing User?  <Link to='/login' className='text-success'>Login</Link></p>
                            </form>
                        </div>
                    </div>
                    



                    
                </div> 
            </div>
        </div>
    </section>  
    </>
  )
}
