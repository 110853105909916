import React from 'react'

export default function Breadcumb(props) {
  return (
    <>
    <section className="innerbanner position-relative py-3 bg-light">
      <div className='container'>
        <div className="pagebannertext">
            <h2 className='h3 mb-2'>{props.pagetitle}</h2>
            <ul className='breadcumb mb-0'>
              <li><a href='/'>Home</a></li>
              <li>{props.pagetitle}</li>
            </ul>
        </div>
      </div>
    </section>
   
    </>
  )
}
