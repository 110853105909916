import React from 'react'

export default function Mcqsearch() {
  return (
    <>
    <form>
        <div className='d-flex mcqsearch'>
            <div className=' flex-fill'>
                <input type='text' className='form-control' placeholder='Search Topic'/>
            </div>
            <div>
                <button className='btn'><i class="fa-regular fa-magnifying-glass"></i> Search</button>
            </div>
        </div>
    </form>
    </>
  )
}
