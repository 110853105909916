import React from 'react'
import Breadcumb from '../../Layouts/Breadcumb'

export default function Askquestion() {
  return (
    <>
    <Breadcumb pagetitle="Ask Question"/>
    <section className='py-5'>
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-lg-7'>
                    <div className='border p-4 rounded shadow-lg customform'>
                        <form>
                            <h2 className='h3 text-center mb-3 pb-4'>Ask Question</h2>
                            <div className='mb-3'>
                                <label className='mb-2 fw-semibold'>Category <span className='text-danger'>*</span></label>
                                <select className='form-control form-select'>
                                    <option>Select Category</option>
                                    <option>Analytics</option>
                                    <option>Company</option>
                                    <option>Language</option>
                                    <option>Management</option>
                                    <option>Programmers</option>
                                    <option>Programs</option>
                                    <option>University</option>
                                    <option>Cyber Security</option>
                                    <option>General</option>
                                </select>
                                <small className='text-muted'> Please choose the appropriate category so others can easily search your question.
                                </small>
                            </div>
                            <div className='mb-3'>
                                <label className='mb-2 fw-semibold'>Question Title <span className='text-danger'>*</span></label>
                                <input type='text' className='form-control' placeholder='Enter Title'/>
                                <small className='text-muted'>Please choose an appropriate title for the question to answer it even easier .</small>
                            </div>
                            <div className='mb-3'>
                                <label className='mb-2 fw-semibold'>Details <span className='text-danger'>*</span></label>
                                <textarea className='form-control' rows="6"></textarea>
                            </div>
                            <div className='mb-3'>
                                <label className='mb-2 fw-semibold'>Details <span className='text-danger'>*</span></label>
                                <input type='text' className='form-control' placeholder='Enter Keyword'/>
                            </div>
                            <div className='mb-3'>
                                <label className='mb-2 fw-semibold'>Featured image</label>
                                <input type='file' className='form-control'/>
                            </div>
                            <div className='mb-3 text-center'>
                                <button className='btn btn-success rounded-pill px-4'>Publish Your Question</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
