import './App.css';
import Header from './Layouts/Header';
import Footer from './Layouts/Footer';
import {BrowserRouter as Router,
    Route, Switch,Routes,
  Link } from "react-router-dom";
import Home from './Component/Home/Home';
import Aboutus from './Component/About/Aboutus';
import Login from './Component/Login/Login';
import RecentQA from './Component/RecentQA/RecentQA';
import Contact from './Component/Contact/Contact';
import Qadetails from './Component/RecentQA/Qadetails';
import Register from './Component/Register/Register';
import Mcq from './Component/Mcq/Mcq';
import Askquestion from './Component/Askquestion/Askquestion';
import Macqlist from './Component/Mcq/Macqlist';
import Mcqresult from './Component/Mcq/Mcqresult';
import Dashboard from './Component/Dashboard/Dashboard';
import Blog from './Component/Blog/Blog';
import BlogDetails from './Component/Blog/BlogDetails';
import Changepassword from './Component/Dashboard/Changepassword';
import UserQuestion from './Component/Dashboard/UserQuestion';
import Notifications from './Component/Dashboard/Notifications';
import Privacypolicy from './Component/Home/Privacypolicy';
import Termandconditions from './Component/Home/Termandconditions';

function App() {
  return (
    <div className="App">
      <Router>
          
      <Header home="Home"  aboutText="About Us" contactText="Contact Us"  loginText="Login"/>
     
          
         <Switch>
              <Route exact path="/"><Home/></Route>
              <Route exact path="/aboutus"><Aboutus/></Route>
              <Route exact path="/login"><Login/></Route>
              <Route exact path="/register"><Register/></Route>
              <Route exact path="/contact"><Contact/></Route>
              <Route exact path="/recent-qa"><RecentQA/></Route>
              <Route exact path="/qa-details"><Qadetails/></Route>
              <Route exact path="/mcq"><Mcq/></Route>
              <Route exact path="/ask-question"><Askquestion/></Route>
              <Route exact path="/mcq-list"><Macqlist/></Route>
              <Route exact path="/result"><Mcqresult/></Route>
              <Route exact path="/blog"><Blog/></Route>
              <Route exact path="/blog-details"><BlogDetails/></Route>

              <Route exact path="/dashboard"><Dashboard/></Route>
              <Route exact path="/change-password"><Changepassword/></Route>
              <Route exact path="/user-question"><UserQuestion/></Route>
              <Route exact path="/notification"><Notifications/></Route>
              <Route exact path="/privacypolicy"><Privacypolicy/></Route>
              <Route exact path="/termandconditions"><Termandconditions/></Route>
          </Switch>

          <Footer/>
      </Router>
     </div>
     
  );
}

export default App;
