import React from 'react'
import Breadcumb from '../../Layouts/Breadcumb'
import DashboardNav from './DashboardNav'

export default function Notifications() {
  return (
    <>
    <Breadcumb pagetitle="Notification"/>
    <section className='py-5'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-3'>
                    <DashboardNav />
                </div>
                <div className='col-lg-9'>
                    <div className='border rounded overflow-hidden'>
                        <h3 className='h5 px-4 py-3  border-bottom bg-success text-white'>Notification</h3>
                        <div className='px-4 py-3'>
                            <a href='#' className='mb-2 border d-block rounded p-2 text-dark'>
                                <div className='h6'>
                                    <h6>Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...</h6>
                                    <small className='text-success fw-normal'>04-08-2024</small>
                                </div>
                            </a>
                            <a href='#' className='mb-2 border d-block rounded p-2 text-dark'>
                                <div className='h6'>
                                    <h6>Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...</h6>
                                    <small className='text-success fw-normal'>04-08-2024</small>
                                </div>
                            </a>
                            <a href='#' className='mb-2 border d-block rounded p-2 text-dark'>
                                <div className='h6'>
                                    <h6>Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...</h6>
                                    <small className='text-success fw-normal'>04-08-2024</small>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
