import React from 'react'
import Breadcumb from '../../Layouts/Breadcumb'
import DashboardNav from './DashboardNav'

export default function Dashboard() {
  return (
    <>
    <Breadcumb pagetitle="Dahboard" />
    <section className='py-5'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-3'>
                    <DashboardNav/>
                </div>
                <div className='col-lg-9'>
                  <div className='border rounded overflow-hidden'>
                    <h3 className='h5 px-4 py-3  border-bottom bg-success text-white'>Profile</h3>
                    <div className='px-4 py-3'>
                      <form>
                        <div className='row'>
                          <div className='col-lg-6 mb-3'>
                            <label className='mb-1'>Full Name <span className='text-danger'>*</span></label>
                            <input type='text' className='form-control'/>
                          </div>
                          <div className='col-lg-6 mb-3'>
                            <label className='mb-1'>E-Mail <span className='text-danger'>*</span></label>
                            <input type='email' className='form-control'/>
                          </div>
                          <div className='col-lg-6 mb-3'>
                            <label className='mb-1'>Password <span className='text-danger'>*</span></label>
                            <input type='password' className='form-control'/>
                          </div>
                          <div className='col-lg-6 mb-3'>
                            <label className='mb-1'>Country <span className='text-danger'>*</span></label>
                            <select className='form-control form-select'>
                              <option>Select</option>
                              <option>India</option>
                              <option>USA</option>
                            </select>
                          </div>
                          <div className='col-lg-6 mb-3'>
                            <label className='mb-1'>City <span className='text-danger'>*</span></label>
                            <input type='text' className='form-control'/>
                          </div>
                          <div className='col-lg-6 mb-3'>
                            <label className='mb-1'>Phone <span className='text-danger'>*</span></label>
                            <input type='text' className='form-control'/>
                          </div>
                          <div className='col-lg-6 mb-3'>
                            <label className='mb-1'>Gender <span className='text-danger'>*</span></label>
                            <div className='d-flex gap-4'>
                              <label><input type='radio' name='gender'/> Male</label>
                              <label><input type='radio' name='gender'/> Female</label>
                            </div>
                          </div>
                          <div className='col-lg-6 mb-3'>
                            <label className='mb-1'>Age <span className='text-danger'>*</span></label>
                            <input type='text' className='form-control'/>
                          </div>
                          <div className='col-lg-6 mb-3'>
                            <label className='mb-1'>Profile Picture <span className='text-danger'>*</span></label>
                            <input type='file' className='form-control'/>
                          </div>
                          <div className='col-lg-6 mb-3'>
                            <label className='mb-1'>About Yourself <span className='text-danger'>*</span></label>
                            <input type='text' className='form-control'/>
                          </div>
                          <div className='col-lg-12 mb-3 text-end'>
                            <button className='btn btn-success'>Update Profile</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
