import React from 'react'
import Breadcumb from '../../Layouts/Breadcumb'
import DashboardNav from './DashboardNav'

export default function Changepassword() {
  return (
    <>
    <Breadcumb pagetitle="Change Password"/>
    <section className='py-5'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-3'>
                    <DashboardNav/>
                </div>
                <div className='col-lg-9'>
                  <div className='border rounded overflow-hidden'>
                    <h3 className='h5 px-4 py-3  border-bottom bg-success text-white'>Change Password</h3>
                    <div className='px-4 py-3'>
                      <form>
                        <div className='row'>
                          <div className='col-lg-7 mb-3'>
                            <label className='mb-1'>Current Password <span className='text-danger'>*</span></label>
                            <input type='password' className='form-control'/>
                          </div>
                          <div className='col-lg-7 mb-3'>
                            <label className='mb-1'>New Password <span className='text-danger'>*</span></label>
                            <input type='password' className='form-control'/>
                          </div>
                          <div className='col-lg-7 mb-3'>
                            <label className='mb-1'>Reenter Password <span className='text-danger'>*</span></label>
                            <input type='password' className='form-control'/>
                          </div>
                          
                          <div className='col-lg-7 mb-3'>
                            <button className='btn btn-success'>Update Password</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
