import React from 'react'
import {
   Link
} from "react-router-dom";


export default function DashboardNav() {
  return (
    <>
    <div className='py-4 dashNav'>
        <div className='userdashImg text-center mb-4'>
            <img src='assets/images/author-11.jpg' className='shadow-lg'/>
            <h2 className='h6 mt-3'>John Doe</h2>
        </div>
        <div>
            <Link to='/dashboard' className='navdash d-flex align-items-center justify-content-between'>
              <div className='d-flex align-items-center gap-2'>
                <i class="far fa-user"></i> Profile
              </div>
              <div>
                <i class="fas fa-chevron-right"></i>
              </div>
            </Link>
            <Link to='/user-question' className='navdash d-flex align-items-center justify-content-between'>
              <div className='d-flex align-items-center gap-2'>
                <i class="far fa-question-circle"></i> User Question
              </div>
              <div>
                <i class="fas fa-chevron-right"></i>
              </div>
            </Link>
            <Link to='#' className='navdash d-flex align-items-center justify-content-between '>
              <div className='d-flex align-items-center gap-2'>
                <i class="far fa-comment"></i> User Answer
              </div>
              <div>
                <i class="fas fa-chevron-right"></i>
              </div>
            </Link>
            <Link to='/notification' className='navdash d-flex align-items-center justify-content-between'>
              <div className='d-flex align-items-center gap-2'>
                <i class="far fa-bell"></i> Notification
              </div>
              <div>
                <i class="fas fa-chevron-right"></i>
              </div>
            </Link>
            <Link to='/change-password' className='navdash d-flex align-items-center justify-content-between'>
              <div className='d-flex align-items-center gap-2'>
                <i class="far fa-key"></i> Change Password
              </div>
              <div>
                <i class="fas fa-chevron-right"></i>
              </div>
            </Link>
            <Link to='#' className='navdash d-flex align-items-center justify-content-between'>
              <div className='d-flex align-items-center gap-2'>
                <i class="fas fa-power-off"></i> Logout
              </div>
              <div>
                <i class="fas fa-chevron-right"></i>
              </div>
            </Link>
        </div>
    </div>
    </>
  )
}
