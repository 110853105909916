import React from 'react'
import Breadcumb from '../../Layouts/Breadcumb'


export default function Aboutus() {
  
  return (
    <>
    <Breadcumb pagetitle="About Us"/>
      
    <section className='py-5'>
      <div className='container'>
        <p>
          

Welcome to TheCognize, your ultimate online destination for competitive exam and interview preparation. We are a dedicated community committed to helping you excel in your academic and professional pursuits through a comprehensive suite of resources designed to enhance your preparation journey.
</p>
<h3>Our Mission:</h3>

<p>At TheCognize, our mission is to support and empower learners by providing a diverse array of resources and tools tailored to meet the challenges of competitive exams and interviews. Our platform is designed to deliver high-quality content that is both accessible and effective, ensuring you have everything you need to achieve your goals.</p>

<h3>What We Offer:</h3>

<p>1. Extensive Question & Answer Database: Dive into our vast collection of practice questions and detailed answers covering a wide range of exams and interviews. Whether you are preparing for academic tests, professional certifications, or job interviews, our database is continually updated to reflect current trends and standards.</p>

<p>2. Insightful Blogs: Explore our collection of blogs that offer valuable advice and strategies for exam and interview preparation. Our blogs cover a variety of topics, from study techniques and time management to stress reduction and career advice, helping you stay informed and motivated.</p>

<p>3. Practice Multiple-Choice Questions (MCQs): Test your knowledge and gauge your readiness with our comprehensive MCQ sets. Our MCQs are designed to mimic the format and difficulty of real exams, allowing you to practice and improve your performance effectively.</p>

<p>4. Interactive Learning Tools:Engage with our interactive tools and resources, including quizzes, flashcards, and mock tests. These tools are designed to make your study sessions more productive and enjoyable, helping you retain information and build confidence.</p>

<p>5. Personalized Guidance:Benefit from tailored advice and support from experts who understand the nuances of various exams and interviews. Our platform offers personalized tips and feedback to help you focus on your strengths and address areas that need improvement.</p>

<h3>Why Choose TheCognize? </h3>

<p>- Comprehensive Coverage: We provide a one-stop solution for all your preparation needs, offering a wide range of resources that cater to different learning styles and requirements.</p>
  
<p>- Expert Content:Our content is created by experienced professionals and educators who bring a wealth of knowledge and practical insights, ensuring that you receive accurate and effective preparation materials.</p>

<p>- User-Friendly Experience:Our intuitive website design makes it easy to navigate and access the resources you need. Whether you’re studying from a computer or mobile device, our platform is designed for convenience and accessibility.</p>


<p>- Supportive Community: Join a vibrant community of fellow learners who share your goals and aspirations. Our forums and discussion groups offer a space for collaboration, motivation, and mutual support.</p>

<p>At TheCognize, we are dedicated to your success. Our goal is to provide you with the resources, tools, and support necessary to excel in your exams and interviews. Explore our platform today and take the next step towards achieving your dreams! </p>

          
      </div>  
    </section>  
    </>
  )
}
