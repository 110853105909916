import React from 'react'
import Breadcumb from '../../Layouts/Breadcumb'

export default function Macqlist() {
  return (
    <>
        <Breadcumb pagetitle="Certified Ethical Hacker exam questions set -1"/>
        <section className='py-5'>
            <div className='container'>
                <form action='/result'>
                    <h3 className='h4 mb-4 border-bottom pb-3 border-light-subtle text-success'>Certified Ethical Hacker exam questions set -1</h3>
                    <div className='questionList border border-light rounded p-3 mb-3'>
                        <h4 className='h5 mb-3'>1) Which of the following takes advantage of weaknesses in the fragment reassembly functionality of TCP/IP?</h4>
                        <div className='mb-2 quebox'><label> <input type='radio' name='que1'/> Teardrop</label></div>
                        <div className='mb-2 quebox'><label> <input type='radio' name='que1'/>  SYN Flood</label></div>
                        <div className='mb-2 quebox'><label> <input type='radio' name='que1'/> Smurf Attack</label></div>
                        <div className='mb-2 quebox'><label> <input type='radio' name='que1'/> Ping Of Death</label></div>
                    </div>
                    <div className='questionList border border-light rounded p-3 mb-3'>
                        <h4 className='h5 mb-3'>2) Which of the following is an effective deterrent against TCP session hijacking?</h4>
                        <div className='mb-2 quebox'><label> <input type='radio' name='que2'/> Install And Use An HIDS On The System.</label></div>
                        <div className='mb-2 quebox'><label> <input type='radio' name='que2'/>  Install And Use Tripwire On The System</label></div>
                        <div className='mb-2 quebox'><label> <input type='radio' name='que2'/> Enforce Good Password Policy.</label></div>
                        <div className='mb-2 quebox'><label> <input type='radio' name='que2'/> Use Unpredictable Sequence Numbers.</label></div>
                    </div>
                    <div className='questionList border border-light rounded p-3 mb-3'>
                        <h4 className='h5 mb-3'>3) Pen test team member Amy attempts to guess the ISN for a TCP session. Which attack is she most likely carrying out?</h4>
                        <div className='mb-2 quebox'> <label> <input type='radio' name='que3'/> XSS</label></div>
                        <div className='mb-2 quebox'><label> <input type='radio' name='que3'/>  Session Splicing</label></div>
                        <div className='mb-2 quebox'> <label> <input type='radio' name='que3'/> Session Hijacking</label></div>
                        <div className='mb-2 quebox'> <label> <input type='radio' name='que3'/> Multipartite Attack</label></div>
                    </div>
                    <div className='questionList border border-light rounded p-3 mb-3'>
                        <h4 className='h5 mb-3'>4) Which of the following is a wireless network detector that is commonly found on Linux?</h4>
                        <div className='mb-2 quebox'> <label> <input type='radio' name='que4'/> Kismet</label></div>
                        <div className='mb-2 quebox'><label> <input type='radio' name='que4'/>  Abel</label></div>
                        <div className='mb-2 quebox'> <label> <input type='radio' name='que4'/> Netstumbler</label></div>
                        <div className='mb-2 quebox'> <label> <input type='radio' name='que4'/> Nessus</label></div>
                    </div>
                    <div className='text-center mt-4'>
                        <button className='btn btn-warning px-4 m-1'>Save</button>
                        <button className='btn btn-success px-4 m-1' type='submit'>Submit</button>
                    </div>
                </form>
            </div>
        </section>
    </>
  )
}
