import React from 'react';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";

export default function Homebanner() {
  return (
    <>
      <section className="bannerhome position-relative">
        <div id="carouselExampleFade" className="carousel slide carousel-fade">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src="assets/images/slide1.png" className="w-100 bannerimg" />
              <div className="bannercontent">
                <div className='container'>
                  <div className='col-lg-5'>
                    <h2>Join the world's biggest Q&A network!</h2>
                    <p>Connect with millions of experts and enthusiasts on the world's largest Q&A network.</p>
                    <Link to='/register' className='btn btn-warning px-5 text-white'>Join Now</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img src="assets/images/slide2.jpg" className="w-100 bannerimg" />
              <div className="bannercontent">
                <div className='container'>
                  <div className='col-lg-5'>
                    <h2>Ask, Share & Discover</h2>
                    <p>Ask questions, share knowledge, and discover answers across countless topics.</p>
                    <Link to='/register' className='btn btn-warning px-5 text-white'>Join Now</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </section>
    </>
  )
}
