import React from 'react'

export default function Blockonebox() {
  return (
    <>
        <div className='row g-4'>
            <div className='col-lg-4'>
                <div className='card boxlabel1 shadow text-center p-4'>
                    <div className='card-body'>
                        <div className='mb-4 text-center'>
                            <img src='assets/images/web-design.png'/>
                        </div>
                        <h2 className='fw-semibold h5 mb-3'>Public Q&A</h2>
                        <p>Engage in public Q&A discussions and get answers from a diverse community of experts . Share your knowledge and help others by participating in open ,insightful conversations.</p>
                        <a href='javascript:void(0)' className='btn btn-success text-white px-4'>Try for free <i class="fa-sharp fa-regular fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <div className='col-lg-4'>
                <div className='card boxlabel1 shadow text-center p-4'>
                    <div className='card-body'>
                        <div className='mb-4 text-center'>
                            <img src='assets/images/secure-data.png'/>
                        </div>
                        <h2 className='fw-semibold h5 mb-3'>Private Q&A</h2>
                        <p>Access private Q&A sessions for personalized guidance and confidential advice. Get expert answers tailored to your needs in a secure, focused environment.</p>
                        <a href='javascript:void(0)' className='btn btn-warning text-white px-4'>Try for free <i class="fa-sharp fa-regular fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <div className='col-lg-4'>
                <div className='card boxlabel1 shadow text-center p-4'>
                    <div className='card-body'>
                        <div className='mb-4 text-center'>
                            <img src='assets/images/diversity.png'/>
                        </div>
                        <h2 className='fw-semibold h5 mb-3'>Large Community</h2>
                        <p>Thecognize is a vast community focused on learning and mastering skills. Members collaborate to enhance their tech proficiency and share valuable insights.</p>
                        <a href='javascript:void(0)' className='btn btn-success text-white px-4'>Join Now <i class="fa-sharp fa-regular fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
