import React from 'react'
import Blocktwobox from './Blocktwobox'

export default function Blocktwo() {
  return (
    <>
        <section className='py-5 bg-light'>
            <div className='container'>
                <h2 className='h1 fw-bold text-center mb-5'>Learn and grow with The Cognize</h2>
            </div>
            <div className='container'>
                <Blocktwobox/>
            </div>
        </section>
    </>
  )
}
